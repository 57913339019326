<template>
  <!-- style="filter:grayscale(1)" -->
  <div id="app" :class="getSelectSkin=='1'?'app-active':''">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/headerPage.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  computed: {
    getSelectSkin() {
      return this.$store.state.Skin.data.toString();
    },
  },
};
</script>

<style>
@import url("@/style/all/all.css");
.app-active{
  filter:grayscale(1)
}
</style>
