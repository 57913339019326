<template>
  <div :class="getSelectSkin==2 ? 'header header-active' : 'header'">
    <div v-if="getSelectSkin!=2" class="left">
      <img src="@/assets/image/header/u176.png" alt="" />
    </div>
    <div v-if="getSelectSkin==2" class="left">
      <img src="@/assets/image/header/u219.jpg" alt="" />
    </div>
    <div class="right">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-menu-item index="1"
          ><router-link to="/">首页</router-link></el-menu-item
        >
        <el-menu-item index="2">
          <router-link to="/aboutUs">关于我们</router-link>
        </el-menu-item>
        <el-submenu index="3">
          <template slot="title">数字创新</template>
          <el-menu-item
            style="font-size: 20px !important; color: black !important"
            index="3-1"
          >
            <router-link
              to="/numberPlanPage"
              style="font-size: 20px !important; color: black !important"
              >数字规划</router-link
            >
          </el-menu-item>
          <el-menu-item
            style="font-size: 20px !important; color: black !important"
            index="3-2"
          >
            <router-link
              style="font-size: 20px !important; color: black !important"
              to="/threeDimensionalModel"
              >数字设计</router-link
            >
          </el-menu-item>
          <el-menu-item
            style="font-size: 20px !important; color: black !important"
            index="3-3"
          >
            <router-link
              to="/numberInfrastructure"
              style="font-size: 20px !important; color: black !important"
              >数字基建</router-link
            >
          </el-menu-item>

          <el-menu-item
            style="font-size: 20px !important; color: black !important"
            index="3-3"
          >
            <router-link
              to="/digitalHandover"
              style="font-size: 20px !important; color: black !important"
              >数字移交</router-link
            >
          </el-menu-item>

          <el-menu-item
            style="font-size: 20px !important; color: black !important"
            index="3-3"
          >
            <router-link
              to="/numberOperation"
              style="font-size: 20px !important; color: black !important"
              >数字运维</router-link
            >
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="4">
          <router-link to="/demonstrationProject">示范工程</router-link>
        </el-menu-item>
        <el-menu-item index="5">
          <router-link to="/smartEnergy">智慧能源</router-link>
        </el-menu-item>
        <el-menu-item index="6">
          <router-link to="/noticeAnnouncement">通知公告</router-link>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "0",
      show: true,
    };
  },
  computed: {
    getSelectSkin() {
      return this.$store.state.Skin.data.toString();
    },
  },
};
</script>

<style lang='less' scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  box-shadow: 0 0 15px 0px #eeeeee;

  .left {
    width: 391px;
    height: 106px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .el-menu-item {
    font-size: 20px;
    color: black;
  }

  /deep/.el-submenu__title {
    font-size: 20px !important;
    color: black !important;
    border: 0 !important;
    line-height: 66px !important;
  }
  .is-opened {
    font-size: 20px !important;
    color: black !important;
  }

  /deep/.el-icon-arrow-down:before {
    content: "\e6df";
    color: black;
    font-size: 16px;
    font-weight: 600;
  }
  /deep/.is-active {
    border-bottom: 0px solid #409eff !important;
    color: #303133;
  }
  /deep/.el-menu.el-menu--horizontal {
    border-bottom: solid 0px #e6e6e6 !important;
  }
  .el-menu-item {
    border-bottom: solid 0px #e6e6e6 !important;
  }
}
.header-active {
  background: url("@/assets/image/header/bj3.png");
  /deep/.el-menu {
    background-color: transparent;
  }
  .el-menu-item {
    color: #fff;
  }
  /deep/.el-submenu__title {
    font-size: 20px !important;
    color: #fff !important;
    border: 0 !important;
    line-height: 66px !important;
  }
  /deep/.el-submenu__title:hover {
    background-color: transparent !important;
  }
  /deep/.el-icon-arrow-down:before {
    color: #fff;
  }
  /deep/.el-menu-item:not(.is-disabled):hover{
    background-color: transparent !important;
    color: #fff;
  }
  /deep/.is-active{
    background-color: transparent;
  }
  /deep/.router-link-active{
    color: #fff;
  }
}
</style>