
import Vue from 'vue';

import Vuex from 'vuex';
Vue.use(Vuex)
let store = new Vuex.Store({
    state: {
        title: {
            name: "",
            path: "/",
            title: "",
        },
        Skin:{
            data:''
        }

    },
    mutations: {
        titleChange(state, data) {
            state.title = data
        },
        selectSkin(state,data){
            state.Skin.data=data
        }
    },
    actions: {

    },
    modules: {
        // home
    }
})
export default store