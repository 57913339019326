<template>
  <div class="footer">
    <div class="content">
      <div class="footer-left">
        <div class="footer-img">
          <img src="@/assets/image/home/logo.png" alt="" />
        </div>
        <p>河南省输变电工程数字化设计管理中心</p>
      </div>
      <div class="footer-content">
        <p>联系我们</p>
        <p>邮箱：sjzx@ha.sgcc.com.cn</p>
        <p>电话：6790142</p>
      </div>
      <div class="footer-right">
        <p>友站链接</p>
        <p><a href="#">电网河南省电力公司官网</a></p>
        <p><a href="#">国家电网公司</a></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.footer {
  background-color: #111d30;
  padding: 20px;
  clear: both;
  .content {
    display: flex;
    justify-content: space-around;
    color: #fff;
    p {
      padding: 10px 0;
    }
    .footer-right {
      a:hover {
        color: red;
      }
    }
    .footer-img {
      max-width: 130px;
      margin-left: 60px;
    }
  }
}
</style>
