import service from '@/request/request.js';
export function getNews(data) {
    return service({
        url: 'notice/page',
        method: 'GET',
        params: data
    })
}
export function getNewText(data) {
    return service({
        url: 'notice/info',
        method: 'GET',
        params: data
    })
}
export function getCase(data) {
    return service({
        url: 'case/page',
        method: 'GET',
        params: data
    })
}
export function getCaseInfo(data) {
    return service({
        url: 'case/caseInfo',
        method: 'GET',
        params: data
    })
}

export function getSwiper(){
    return service({
        url: 'info_image/list',
        method: 'GET',
    })
}

export function getSkin(){
    return service({
        url: 'skin/get',
        method: 'GET',
    })
}

