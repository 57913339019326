import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/index.js'
import {getSkin} from '@/request/api'
Vue.use(Router)
const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/homePage.vue'),
        meta: { title: '首页' },
        children: [],
    },
    {
        path: '',
        name: 'layout',
        component: () => import('@/layout/layoutPage.vue'),
        children: [
            {
                path: '/aboutUs',
                name: 'aboutUs',
                component: () => import('@/views/aboutus/aboutusPage.vue'),
                meta: {
                    title: '关于我们',
                    titleText: {
                        name: '关于我们',
                        path: '/aboutUs',
                        title: [{
                            title: '关于我们',
                            path: '/aboutUs',
                        }]
                    }
                },
            },
            {
                path: '/numberPlanPage',
                name: 'numberPlanPage',
                component: () => import('@/views/numberBringAbout/numberPlanPage.vue'),
                meta: {
                    title: '数字规划',
                    titleText: {
                        name: '数字规划',
                        title: [{
                            title: '数字规划',
                            path: '/numberPlanPage',
                        }]
                    }
                },
            },

            {
                path: '/IntelligentLocation',
                name: 'IntelligentLocation',
                component: () => import('@/views/numberBringAbout/IntelligentLocationPage.vue'),
                meta: {
                    title: '智能选址选线',
                    titleText: {
                        name: '智能选址选线',
                        path: '/IntelligentLocation',
                        title: [{
                            title: '数字规划',
                            path: '/numberPlanPage',
                        },
                        {
                            title: '智能选址选线',
                            path: '/IntelligentLocation',
                        }]
                    }
                },
            },
            {
                path: '/direct',
                name: 'direct',
                component: () => import('@/views/numberBringAbout/directPage.vue'),
                meta: {
                    title: '直流偏磁研究',
                    titleText: {
                        name: '直流偏磁研究',
                        path: '/IntelligentLocation',
                        title: [{
                            title: '数字规划',
                            path: '/numberPlanPage',
                        },
                        {
                            title: '直流偏磁研究',
                            path: '/direct',
                        }]

                    }
                },
            },
            {
                path: '/hreeDimensionalCooperate',
                name: 'hreeDimensionalCooperate',
                component: () => import('@/views/numberBringAbout/numberDesign/hreeDimensionalCooperatePage.vue'),
                meta: {
                    title: '三维协同设计',
                    titleText: {
                        name: '三维协同设计',
                        path: '/hreeDimensionalCooperate',
                        title: [{
                            title: '三维协同设计',
                            path: '/hreeDimensionalCooperate'
                        }]

                    }
                },
            },
            {
                path: '/threeDimensionalModel',
                name: 'threeDimensionalModel',
                component: () => import('@/views/numberBringAbout/numberDesign/threeDimensionalModelPage.vue'),
                meta: {
                    title: '三维模型共享',
                    titleText: {
                        name: '三维模型共享',
                        title: [{
                            title: '三维模型共享',
                            path: '/threeDimensionalModel',
                        }],
                        path: '/threeDimensionalModel'

                    }
                },
            },
            {
                path: '/threeDimensionalUsed',
                name: 'threeDimensionalUsed',
                component: () => import('@/views/numberBringAbout/numberDesign/threeDimensionalUsedPage.vue'),
                meta: {
                    title: '三维通用设计',
                    titleText: {
                        name: '三维通用设计',
                        title: [{
                            path: '/threeDimensionalUsed',
                            title: '三维通用设计'
                        }],
                        path: '/threeDimensionalUsed',

                    }
                },
            },
            {
                path: '/hreeDimensionalCooperate/civilEngineering',
                name: 'civilEngineering',
                component: () => import('@/views/numberBringAbout/numberDesign/children/civilEngineering.vue'),
                meta: {
                    title: '土建',
                    titleText: {
                        name: '土建',
                        path: '/hreeDimensionalCooperate/civilEngineering',

                        title: [{
                            path: '/hreeDimensionalCooperate',
                            title: '三维协同设计'
                        },
                        {
                            path: '/hreeDimensionalCooperate/civilEngineering',
                            title: '土建'
                        }],
                    }
                },
            },
            {
                path: '/hreeDimensionalCooperate/std-r',
                name: 'civilEngineering',
                component: () => import('@/views/numberBringAbout/numberDesign/children/std-RPage.vue'),
                meta: {
                    title: 'std-r',
                    titleText: {
                        name: 'std-r',
                        path: '/hreeDimensionalCooperate/std-r',
                        title: [{
                            path: '/hreeDimensionalCooperate',
                            title: '三维协同设计'
                        },
                        {
                            path: '/hreeDimensionalCooperate/std-r',
                            title: 'std-r'
                        }]

                    }
                },
            },
            {
                path: '/hreeDimensionalCooperate/secondary',
                name: 'secondary',
                component: () => import('@/views/numberBringAbout/numberDesign/children/secondaryPage.vue'),
                meta: {
                    title: '二次页面',
                    titleText: {
                        name: 'secondary',
                        path: '/hreeDimensionalCooperate/secondary',
                        title: [{
                            path: '/hreeDimensionalCooperate',
                            title: '三维协同设计'
                        },
                        {
                            path: '/hreeDimensionalCooperate/secondary',
                            title: '二次页面'
                        }]

                    }
                },
            },
            {
                path: '/hreeDimensionalCooperate/line',
                name: 'civilEngineering',
                component: () => import('@/views/numberBringAbout/numberDesign/children/linePage.vue'),
                meta: {
                    title: '线路',
                    titleText: {
                        name: '线路',
                        path: '/hreeDimensionalCooperate/line',
                        title: [{
                            path: '/hreeDimensionalCooperate',
                            title: '三维协同设计'
                        },
                        {
                            path: '/hreeDimensionalCooperate/line',
                            title: '线路'
                        }]
                    }
                },
            },
            {
                path: '/numberInfrastructure',
                name: 'numberInfrastructure',
                component: () => import('@/views/numberBringAbout/numberInfrastructure/numberInfrastructure.vue'),
                meta: {
                    title: '数字基建',
                    titleText: {
                        name: '数字基建',
                        path: '/numberInfrastructure',
                        title: [{
                            title: '数字基建',
                            path: '/numberInfrastructure'
                        }]
                    }
                },
            },
            {
                path: '/numberInfrastructure/progressManagement',
                name: 'progressManagement',
                component: () => import('@/views/numberBringAbout/numberInfrastructure/children/progressManagementPage.vue'),
                meta: {
                    title: '进度管理分析评价研究',
                    titleText: {
                        name: '进度管理分析',
                        path: '/numberInfrastructure/progressManagement',
                        title: [
                            {
                                title: '数字基建',
                                path: '/numberInfrastructure'
                            }, {
                                title: '进度管理分析',
                                path: '/numberInfrastructure/progressManagement'
                            }
                        ]
                    }
                },
            },
            {
                path: '/numberInfrastructure/engineeringCost',
                name: 'engineeringCost',
                component: () => import('@/views/numberBringAbout/numberInfrastructure/children/engineeringCostPage.vue'),
                meta: {
                    title: '工程成本可视化研究',
                    titleText: {
                        name: '工程成本',
                        path: '/numberInfrastructure/engineeringCost',
                        title: [
                            {
                                title: '数字基建',
                                path: '/numberInfrastructure'
                            }, {
                                title: '工程成本可视化研究',
                                path: '/numberInfrastructure/engineeringCost'
                            }
                        ]
                    }
                },
            },
            {
                path: '/digitalHandover',
                name: 'engineeringCost',
                component: () => import('@/views/numberBringAbout/digitalHandover/digitalHandoverPage.vue'),
                meta: {
                    title: '数字移交',
                    titleText: {
                        name: '数字移交',
                        path: '/digitalHandover',
                        title: [{
                            title: '数字移交',
                            path: '/digitalHandover'
                        }]
                    }
                },
            },
            {
                path: '/numberOperation',
                name: 'numberOperation',
                component: () => import('@/views/numberBringAbout/numberOperation/numberOperationPage.vue'),
                meta: {
                    title: '数字运维',
                    titleText: {
                        name: '数字运维',
                        path: '/numberOperation',
                        title: [{
                            title: '数字运维',
                            path: '/numberOperation'
                        }]
                    }
                },
            },
            {
                path: '/numberOperation/selectEquipment',
                name: 'selectEquipment',
                component: () => import('@/views/numberBringAbout/numberOperation/children/selectEquipmentPage.vue'),
                meta: {
                    title: '选好设备',
                    titleText: {
                        name: '选好设备',
                        path: '/numberOperation/selectEquipment',
                        title: [{
                            title: '数字运维',
                            path: '/numberOperation'
                        }, {
                            title: '选好设备',
                            path: '/numberOperation/selectEquipment'
                        }]
                    }
                },
            },
            {
                path: '/numberOperation/lccDemonstration',
                name: 'selectEquipment',
                component: () => import('@/views/numberBringAbout/numberOperation/children/lccDemonstrationPage.vue'),
                meta: {
                    title: 'LCC论证模板',
                    titleText: {
                        name: 'LCC论证模板',
                        path: '/numberOperation/lccDemonstration',
                        title: [
                            {
                                title: '数字运维',
                                path: '/numberOperation'
                            }, {
                                title: 'LCC论证模板',
                                path: '/numberOperation/lccDemonstration'
                            }
                        ]
                    }
                },
            },
            {
                path: '/smartEnergy',
                name: 'smartEnergy',
                component: () => import('@/views/smartEnergy/smartEnergyPage.vue'),
                meta: {
                    title: '智慧能源',
                    titleText: {
                        name: '智慧能源',
                        path: '/smartEnergy',
                        title: [{
                            title: '智慧能源',
                            path: '/smartEnergy'
                        }]
                    }
                },
            },
            {
                path: '/smartEnergy/powerSystem',
                name: 'smartEnergy',
                component: () => import('@/views/smartEnergy/children/powerSystem.vue'),
                meta: {
                    title: '储能配置及设计',
                    titleText: {
                        name: '智慧能源',
                        path: '/smartEnergy/powerSystem',
                        title: [{
                            title: '智慧能源',
                            path: '/smartEnergy'
                        }, {
                            title: '储能配置及设计',
                            path: '/smartEnergy/powerSystem'
                        }]
                    }
                },
            },
            {
                path: '/smartEnergy/accessDesign',
                name: 'smartEnergy',
                component: () => import('@/views/smartEnergy/children/accessDesign.vue'),
                meta: {
                    title: '分布式电源接入设计',
                    titleText: {
                        name: '智慧能源',
                        path: '/smartEnergy/accessDesign',
                        title: [{
                            title: '智慧能源',
                            path: '/smartEnergy'
                        }, {
                            title: '分布式电源接入设计',
                            path: '/smartEnergy/accessDesign'
                        }]
                    }
                },
            },
            {
                path: '/smartEnergy/collaborativeDesign',
                name: 'smartEnergy',
                component: () => import('@/views/smartEnergy/children/collaborativeDesign.vue'),
                meta: {
                    title: '二次网站协同设计',
                    titleText: {
                        name: '智慧能源',
                        path: '/smartEnergy/collaborativeDesign',
                        title: [{
                            title: '智慧能源',
                            path: '/smartEnergy'
                        }, {
                            title: '二次网站协同设计',
                            path: '/smartEnergy/collaborativeDesign'
                        }]
                    }
                },
            },
            {
                path: '/smartEnergy/loadStorageDesign',
                name: 'smartEnergy',
                component: () => import('@/views/smartEnergy/children/loadStorageDesign.vue'),
                meta: {
                    title: '源网荷储设计',
                    titleText: {
                        name: '智慧能源',
                        path: '/smartEnergy/loadStorageDesign',
                        title: [{
                            title: '智慧能源',
                            path: '/smartEnergy'
                        }, {
                            title: '源网荷储设计',
                            path: '/smartEnergy/loadStorageDesign'
                        }]
                    }
                },
            },
            {
                path: '/noticeAnnouncement',
                name: 'smartEnergy',
                component: () => import('@/views/noticeAnnouncement/noticeAnnouncementPage.vue'),
                meta: {
                    title: '通知公告',
                    titleText: {
                        name: '通知公告',
                        path: '/noticeAnnouncement',
                        title: [{
                            path: '/noticeAnnouncement',
                            title: '通知公告'
                        }]
                    }
                },
            },
            {
                path: '/demonstrationProject',
                name: 'demonstrationProject',
                component: () => import('@/views/demonstrationProject/demonstrationProjectPage.vue'),
                meta: {
                    title: '示范工程',
                    titleText: {
                        name: '示范工程',
                        path: '/demonstrationProject',
                        title: [
                            {
                                title: '示范工程',
                                path: '/demonstrationProject'
                            }
                        ]
                    }
                },
            },
            {
                path: '/demonstrationProject/projectDetails',
                name: 'projectDetails1',
                component: () => import('@/views/demonstrationProject/children/projectDetailsPage.vue'),
                meta: {
                    title: '示范工程',
                    titleText: {
                        name: '示范工程',
                        path: '/demonstrationProject/projectDetails',
                        title: [
                            {
                                title: '示范工程',
                                path: '/demonstrationProject'
                            },
                            {
                                title: '示范工程',
                                path: '/demonstrationProject/projectDetails'
                            }
                        ]
                    }
                },
            },
            {
                path: '/noticeAnnouncement/articleTextPage',
                name: 'articleText',
                component: () => import('@/views/noticeAnnouncement/articleTextPage.vue'),
                meta: {
                    title: '公告详情',
                    titleText: {
                        name: '公告详情',
                        path: '/articleTextPage',
                        title: [{
                            title: '通知公告',
                            path: '/noticeAnnouncement',

                        }, {
                            title: '公告详情',
                            path: '/noticeAnnouncement/articleTextPage',
                        }]
                    }
                },
            },
        ],
    },

]
let router = new Router({
    routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
        next();
        sessionStorage.setItem('title', JSON.stringify(to.meta.titleText))
        store.commit(
            "titleChange",
            JSON.parse(sessionStorage.getItem("title"))
        );
        getSkin().then((res) => {
            console.log(res);
            localStorage.setItem('Skin',JSON.stringify(res.data.data))
            store.commit( "selectSkin",localStorage.getItem('Skin'));

        });
    }

})
export default router